@font-face {
font-family: 'graphik';
src: url(/peer-community/nextjsv2/_next/static/media/eec065f2be8bf957.woff2) format('woff2');
font-display: optional;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'graphik';
src: url(/peer-community/nextjsv2/_next/static/media/3483eb270027278b.woff2) format('woff2');
font-display: optional;
font-weight: 500;
font-style: normal;
}.__className_ec09ad {font-family: 'graphik', Helvetica Neue, sans-serif
}.__variable_ec09ad {--font-graphik: 'graphik', Helvetica Neue, sans-serif
}

